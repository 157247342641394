// @ts-check
import { initSchema } from '@aws-amplify/datastore';
import { schema } from './schema';



const { User, ViewingsSession, Viewing, Viewer, Property, PropertyMarketPlace, Keys, ReservedSlot, ClemAvailability, ViewingReport, SessionReport, Lead, MarketPlaceConfig, BillDay, BillMonth, AIPrompt, AIChat, AIPrequalProperty, AIPrequal } = initSchema(schema);

export {
  User,
  ViewingsSession,
  Viewing,
  Viewer,
  Property,
  PropertyMarketPlace,
  Keys,
  ReservedSlot,
  ClemAvailability,
  ViewingReport,
  SessionReport,
  Lead,
  MarketPlaceConfig,
  BillDay,
  BillMonth,
  AIPrompt,
  AIChat,
  AIPrequalProperty,
  AIPrequal
};